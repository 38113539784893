<template>
    <v-card >
        <s-toolbar label="Vista de camaras"
            dark 
            close 
            @close="closeDialogViewChamber()"
            color="primary"
        >
        </s-toolbar>
        <v-card-text>
            <v-row>
                <v-col >
                    <v-row>
                        <v-col cols="4">
                            <s-select
                                :items="SelectChamber.tunnelsDetails"
                                label="Lado"
                                item-text="SideDescription"
                                item-value="CddID"
                                v-model="SelectSide" 
                                return-object
                                @input="clickSide($event)"
                            >
                            <!-- @input="clickSide($event)" -->
                            </s-select>
                           
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="auto" v-for="(level, indexNumberLanes) in SelectSide.NumberLanes" align="center" :value="level" :key="indexNumberLanes">
                            <!-- <v-chip  color="warning">Carril {{ level + lane.NumberStart }} </v-chip> -->
                            <v-toolbar class="flex-grow-0" dark color="#5edf87" height="30"  rounded >
                                <v-toolbar-title>{{ 'Carril ' +  parseInt(level + SelectSide.NumberStart)}}</v-toolbar-title>
                            </v-toolbar>

                            <v-card-text>
                                <v-row dense v-for="(carril, index) in SelectSide.NumberLevels" v-bind:key="carril">
                                    <v-col  cols="auto"  class="py-1 pe-0">
                                        <v-card hover  >
                                            <v-toolbar color="#299ff9" height="30" >
                                                <v-toolbar-title><h4>{{Position[index]}}</h4></v-toolbar-title>
                                            </v-toolbar>

                                            <v-row class="mt-1" dense>
                                                <v-col cols="auto"  v-for="(pstn , ix) in SelectSide.PositionsLevel" :key="ix" >
                                                    <v-card  >
                                                        <!-- {{Position[index]}} -->
                                                        <v-card-text>
                                                            {{ pstn }} 
                                                        </v-card-text>
                                                    </v-card>
                                                    <div v-for="(pallet, id) in tunnelInfo.Pallets?.filter((x) => x.SideID == SelectSide.SideID
                                                        && x.NumberLanes == level -1
                                                        && x.NumberLevels == carril-1
                                                        && x.PositionsLeve == pstn - 1)" :key="id"
                                                    >
                                                        <div class="positionLaneBusy" 
                                                            :class="{ positionLaneBusy: pallet.IsObserved != 0, positionLaneBusyObserved: pallet.IsObserved == 1 }"
                                                            @click="detailPalletInPosition(SelectSide.SideID,level -1,carril-1,pstn - 1)" 
                                                        >
                                                            {{Position[index]}}{{ pstn }}  
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-col> <br>
                    </v-row>
                </v-col>
            </v-row>  
        </v-card-text>     
        <v-dialog
            v-model="dialogDetallePalletInPosition"
            v-if="dialogDetallePalletInPosition"
            persistent
            max-width="1000"
            height="400px"
        >
            <detail-pallet 
                :sider= sider
                :lane = lane
                :level = level
                :pst = pst
                :CdtID = this.SelectChamber.CdtID
                @closeDetallePalletInPosition="closeDetallePalletInPosition()"
            ></detail-pallet>
        </v-dialog>
</v-card>
</template>
<script>
    
    import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
    import DetailPallet from './FrzChamberDetailPalletInPosition.vue';
    // import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";
    import draggable from "vuedraggable";

    export default{
        // directives: {
        //     dragscroll
        // },
        components: {DetailPallet, draggable},
        props:{
            SelectChamber:{
                default: null,
                type: Object,
            }
        },
        data(){
            return {
                itemsTunnel:[],
                dialogDetail: false,
                dialogDetailAdmin: false,
                tunnelInfo: {
                    Pallets: []
                },
                tunnel: {},
                detail: [],
                palletSel: [],
                Position: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
                model: null,
                selectNumberLane: null,
                selectNumberLevel: null,
                selectPositionsLevel: null,
                SideID: null,
                dialogDetallePalletInPosition: false,
                sider : 0,
                lane : 0,
                level : 0,
                pst : 0,
                CdtID :0,
                SelectSide:{},
                PaletsFilters: [],
                IsShow:true,
            }
        },
        methods: {
            closeDialogViewChamber(){
                 
                this.$emit('closeDialogViewChamber');
            },
            closeDetallePalletInPosition(){
                this.dialogDetallePalletInPosition = false;
            },            
            
            getDetail(){
                this.SelectChamber.NumberLanes = this.selectNumberLane;
                this.SelectChamber.NumberLevels = this.selectNumberLevel;
                this.SelectChamber.PositionsLeve = this.selectPositionsLevel;                
                this.SelectChamber.SideID = this.SideID;
                _sPackingTunnel.detail(this.SelectChamber, this.$fun.getUserID())
                .then((r) => {

                    if (r.status == 200) {
                        this.tunnelInfo = r.data;   
                        console.log(this.tunnelInfo);       
                    }
                })
            },
            detailPalletInPosition(sider,lane,level,pst){
                this.dialogDetallePalletInPosition = true
                this.sider = sider;
                this.lane = lane;
                this.level = level;
                this.pst = pst;               
            },
            movedPallet(sider,lane,level,pst){
                // this.dialogDetallePalletInPosition = true
                // this.sider = sider;
                // this.lane = lane;
                // this.level = level;
                // this.pst = pst;               
            },
            clickSide(){
                this.PaletsFilters = [];
            },
            
            
        },
        created(){
            this.getDetail();
        },
        mounted()
        {
            this.SelectSide = this.SelectChamber.tunnelsDetails[0];
            console.log(this.SelectChamber);
            // this.initialize();
        }
    }
</script>
<style>
    .level{
        padding: 20px 0px;
    }
    .contentPosition{
        width: 110px;
        height: 55px;
        border: 1px solid;
        border-color: white;


        /* display: -ms-flexbox; 
        contain: content; */


        /*display: -webkit-box;*/
        /* display: flex;*/
        

    } 
    .positionLane{
        background-color: #4eca8a;
        width: auto;
        height: 45px;
        border-color: #4eca8a;
        border: 1px solid;
        display: inline-block;
        margin: 5px 1px;
        color: white;
        padding: 10px 2px;
        cursor: pointer;
    }
    .positionLaneBusy{
        background-color: #1a5cff;
        width: 30px;
        height: 30px;
        border: 1px solid;
        color: white;
        margin-block: -38px 0px;
        cursor: pointer;
        position: relative;
        z-index: 100!important;
        border-radius: 40px;
        padding: 5px 0px;
    }
    .positionLaneBusyObserved{
        background-color: #dd3b62;
        width: 30px;
        height: 30px;
        border: 1px solid;
        color: white;
        margin-block: -38px 0px;
        cursor: pointer;
        position: relative;
        z-index: 100!important;
        border-radius: 40px;
        padding: 5px 0px;
    }
    .my-slider > .v-slide-group__next, .v-slide-group__prev {
    
        color: rgb(234, 10, 10)!important;
        margin: 0px -21px 0px -21px;
    }
    .scroll {
        overflow-y: scroll
    }

</style>